@import './colors.scss';

//website preloader
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    background: $g2g-white;

    &>div {
        width: 8rem;
        height: 8rem;
        background: url('/images/g2g-logo.png');
        background-size: contain;
        animation: show-loader 4s ease-in-out;
    }

    transition: 1s;
}

.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
}

@keyframes show-loader {
    0% {
        opacity: 0;
        visibility: hidden;
    }

    25% {
        opacity: .5;
        visibility: visible;
    }

    50% {
        opacity: 1;
        visibility: visible;
    }

    75% {
        opacity: .5;
        visibility: visible;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.loading-mask {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(255, 255, 255, 0.3);
    text-align: center;
    display: block;

    &.show {
        display: block;
        opacity: 1;
    }

    &:after {
        content: "";
        display: inline-block;
        width: 46px;
        height: 46px;
        margin: 0;
        border-radius: 50%;
        border: 5px solid $g2g-white;
        border-color: $g2g-gold transparent $g2g-gold $g2g-gold;
        animation: loading-mask 1.2s linear infinite;
        top: 50%;
        transform: translateY(-50%);
        position: relative;
        z-index: 1050;
        text-align: center;
    }
}

@keyframes loading-mask {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
$g2g-gold: #F8A90D;
$g2g-dim-gold: #fab62e;
$g2g-light-gold: #FEF6E7;

$g2g-grey: #90929C;
$g2g-light-grey: #F7F7F7;

$g2g-black: #2A2D42;
$g2g-white: #FFFFFF;

$g2g-dim-grey: rgba(0, 0, 0, 0.15);
$g2g-lighter-grey: #f1f1f1;
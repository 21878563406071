@import "theme.scss";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

/* Order */
@layer reset, primeng;

/* Reset CSS */
@layer reset {
    a:has(.p-tabview-title) {
        color: $g2g-black !important;
        text-decoration: none !important;
        padding: 15px;
        border-bottom: 0;
        border-radius: 10px;
    }

    .p-tabview-panels {
        padding: 10px !important;
    }

    .p-highlight>a.p-tabview-nav-link {
        color: #a97f2e !important;
        background: #fff6e8 !important;
    }

    .p-dialog {
        border-radius: 30px !important;
    }

    .p-tabview-nav {
        display: flex;
        align-items: center;
        gap: 10px;
        border: 0px;
    }

    button,
    input {
        box-shadow: none;
        border-radius: 6px !important;
        caret-color: $g2g-gold;
        /* CSS to Reset */
    }
    
    button {
        border-radius: 30px !important;
    }
    .p-button-primary {
        background-color: rgba(245, 158, 11, 0.16);
    }

    ul {
        padding-left: 0px !important;
    }
}

/* You can add global styles to this file, and also import other style files */
@import 'app/styles/fonts.scss';
@import 'app/styles/flags.scss';
@import 'app/styles/loader.scss';
@import 'app/styles/overrides.scss';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

* {
    outline: none !important;
}

body {
    font-family: Poppins;
}

.text-gold {
    color: $g2g-gold;
}

.text-dim {
    color: rgba(0, 0, 0, 0.5);
}

.font-bold {
    font-family: Poppins-Semibold;
}

.font-medium {
    font-family: Poppins-Medium;
}

.gap-20 {
    gap: 20px;
}

.disabled-control {
    filter: grayscale(1);
    pointer-events: none;
}

*:disabled {
    filter: grayscale(1);
    pointer-events: none;
}

.pointer-cursor {
    cursor: pointer;
}

.border-gold {
    border: 2px solid $g2g-gold;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.single-line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.max-content {
    display: block;
    width: max-content;
}
.flex-0 {
    flex: 0 !important;
}
.horizontal {
    align-items: center;
    justify-content: start;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(350px, 2fr));
    gap: 10px;
}

@import './colors.scss';

.form-check-input:checked {
    background-color: $g2g-gold !important;
    border-color: transparent !important;
}
.form-check-input:focus {
    border-color: $g2g-gold !important;
    box-shadow: 0 0 0 0.25rem $g2g-light-gold !important;
}
// .toast-container {
//     width: 100% !important;
//     padding: 0px !important;
//     margin: 0px !important;
// }
// .toast-container > :not(:last-child) {
//     margin: 0px !important;
// }
// .toast {
//     border: none !important;
//     border-radius: 30px !important;
//     padding: 0px 20px;
// }